import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Welcome</h1>
    <p>Please log in to continue.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
    </div>

  </Layout>
);

export default IndexPage;
